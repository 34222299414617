import React, { useContext, useEffect, useState } from 'react'
import { graphql, Link, PageProps } from 'gatsby'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout, { UserContext, withLayout } from '../components/layout'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import firebase from 'firebase';

import styles from "./service.module.css"
import { Breadcrumbs, Button, CircularProgress } from '@material-ui/core'
import {navigate} from "@reach/router"

const LessonPage = (props: PageProps<GatsbyTypes.LessonBySlugQuery>) => {
  let lesson = props.data.contentfulLesson;
  let course = props.data.contentfulCourse;
  const userContext = useContext(UserContext);
  const [videoURL, setVideoURL] = useState<string | null>(null);
  const [notBought, setNotBought] = useState<boolean | null>(null);

  useEffect(() => {
    if (userContext?.loggedIn) {

      firebase.auth().currentUser?.getIdToken(true).then(async function (idToken) {
        if(!idToken){
          return;
        }

        try {
          await requestVideoFromServer(lesson, setVideoURL, idToken, setNotBought, course?.slug);
        } catch (err) {
          throw err;
        }

      }).catch(function (err) {
        alert("Something went wrong... Please contact us at help@leanqualityacademy.com if you continue experiencing this issue. Error:" + err.toString());
      });
    }else if(userContext?.loggedIn === false){ // not the same thing as else, since its null when it hasnt loaded yet, so we still want spinner
      setNotBought(true);
    }

  }, [userContext?.loggedIn])

  console.log(videoURL);
  console.log("usercontext not bought", userContext?.loggedIn, notBought);
  if(userContext?.loggedIn == null || notBought === null){
    return(
      <CircularProgress />
    )

  }
  if (!userContext?.loggedIn) {
    return (
      <>
        Please <Link to={"/account?redirectOnSuccess="+course?.slug +"/" + lesson?.slug} > <Button>Login</Button></Link> in order to view this page.
      </>
    )
  }
  if (notBought) {
    return (
      <>
        Please buy this course from the <Link to={`/${course?.slug}`}> <Button>Course</Button></Link> page to view this lesson!
      </>
    )
  }



  return (
    <>
      <h1 className={styles.header}>{props.data.contentfulLesson?.title}</h1>
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" to={`/courses`}>
          Courses
        </Link>
        <Link color="inherit" to={`/${course?.slug}`}>
          {course?.title}
        </Link>

        <Link
          color="textPrimary"
          to=""
          onClick={e => e.preventDefault()}
        >
          {lesson?.title}
        </Link>

      </Breadcrumbs>
      <hr></hr>

      {/* {videoURL} */}
      {/* <video src={videoURL} controls  onContextMenu={(e)=> e.preventDefault()}/> */}

      <div className={styles.container}>

        {videoURL == null ? <CircularProgress /> :
        <video
          onError={(e) => { alert("Video Failed to load! Please try reloading the page. If the issue continues happening, please contact us at help@leanqualityacademy.com"); location.reload() }}
          src={videoURL} controls onContextMenu={(e) => e.preventDefault()}
        />}
        
        
      </div>

    </>
  )
}




export default withLayout(LessonPage)



export const pageQuery = graphql`
query LessonBySlug($slug: String!, $courseSlug: String!) {
  contentfulLesson(slug: { eq: $slug }) {
    title
    slug
  }
  contentfulCourse(slug: { eq: $courseSlug }) {
    title
    slug
  }
}
`
async function requestVideoFromServer(lesson: GatsbyTypes.Maybe<Pick<GatsbyTypes.ContentfulLesson, "title" | "slug">>, setVideoURL: React.Dispatch<React.SetStateAction<string | null>>, idToken: string, setNotBought, courseSlug) {
  return fetch(`/.netlify/functions/getCourseVideoLink?slug=${lesson?.slug}.mp4&id=${idToken}&courseSlug=${courseSlug}`).then(res => { console.log(res); return (res.json()) }).then(res => {
    console.log(res)
    if (res.status == 200) {
      setVideoURL(res.url);
      setNotBought(false);
    } else if (res.status == 401) {
      setNotBought(true);
    }
    else {
      throw "request failed with status " + res.status + " " + res.message
    }
  }).catch(err => {
    // console.log(err);
    throw err
  })
}

